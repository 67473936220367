.loading-start {
  width: 15em;
  height: 15em;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  position: absolute;
  z-index: 600;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.625rem;

  @media (min-width: 1500px) {
    font-size: 0.625rem * 1.5;
  }
}

.preloader {
  display: block;
  border-radius: 50%;
  animation: rotate 0.7s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 4rem;
  height: 4rem;
  box-shadow: 0 0.1em 0.1em 0.2em #00c0cb;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
