/* stylelint-disable unit-disallowed-list */
// Vars and mixins to use in Kanto project

// colors
$kanto_brand_color: #00c4cb;

$timeHiden: 0.3s;
$ksFocus: #00c4cb;
$barra-player-sprite: url(/assets/img/barra_player.png);
// $base_picon: -75px;
$background_color_button: rgba(0, 0, 0, 40%);
//$border_color_button: rgba(255, 255, 255, 0.4);
$border_color_button: #191919;

// fonts
$global-font-size: 0.625rem;

// buttons
$global_width_border: 4px;
$button_color_style_low_white: rgba(255, 255, 255, 10%);

@mixin border-radius($x) {
  -webkit-border-radius: $x;
  -moz-border-radius: $x;
  -ms-border-radius: $x;
  border-radius: $x;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// Browser Prefixes
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
  -webkit-transform: $transforms;
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

%colorGreen {
  color: $ksFocus;
}

.info-song-coupon {
  color: #fff;
  width: 340px;
  position: fixed;
  text-align: left;
  left: 0;
  top: 150px;
  font-weight: bold;

  @include transition(left $timeHiden, transform $timeHiden);
  //transform: translateX(-500px);
  @include translate(-500px, 0);
}

$elementWidth: 220px;
